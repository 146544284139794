import { Outlet, Navigate } from "react-router-dom"

const ProtectedRoute = () => {
    const fakeAuth = sessionStorage.getItem('token')
    const builder = () => {
        return (
            <Outlet />
        )
    }
    return !!fakeAuth ? builder() : <Navigate to='/login' />
}

export default ProtectedRoute
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import './assets/css/bootstrap.min.css'
import "../src/assets/plugins/fontawesome/css/fontawesome.min.css"
import "../src/assets/plugins/fontawesome/css/all.min.css"

import "../src/assets/plugins/feather/feather.css"

import "../src/assets/css/bootstrap-datetimepicker.min.css"

import "../src/assets/plugins/datatables/datatables.min.css"

import "../src/assets/css/style.css"

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpeedInsights } from "@vercel/speed-insights/react"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
    <SpeedInsights />
    <ToastContainer/>
  </BrowserRouter>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Outlet, Navigate } from "react-router-dom"
const AuthCheck = ({userRole}) => {

  const role = sessionStorage.getItem('role') 
  const permission_ava = userRole.some(obj => obj === role);

  const builder = () => {
    return (
      <Outlet />
    )
  }
  return permission_ava ? builder() : <Navigate to='/error' />
}

export default AuthCheck